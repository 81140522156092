/* form */
.form-control,
.ms-TextField-fieldGroup {
    border: 1px solid #e5e5e5;
    color: #333;
}
.form-control:hover {
    border-color: hsl(0, 0%, 70%);
}
.form-control:focus {
    border-color: #12344d;
    outline: 0;
    box-shadow: none;
}

.select__control {
    border: 1px solid #e5e5e5;
    color: #333;
    border-radius: 2px;
}
.select__control--is-focused,
.select__control--menu-is-open {
    border-color: #12344d !important;
    outline: 0;
    box-shadow: none;
}

.card-calculator .ms-TextField-suffix {
    width: 50px;
    justify-content: center;
    font-weight: 500;
}

.ms-Label {
    font-weight: 500;
    margin-bottom: 2px;
    font-size: 12px;
    padding: 0;
    font-family: inherit;
}

/*antd*/
.anticon {
    margin-right: 5px;
    vertical-align: middle;
}
.ant-tabs-tab {
    padding: 10px 15px;
}
.ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
}
.ant-tabs-tab .anticon {
    margin-right: 5px;
    vertical-align: middle;
}

.btn .anticon {
    margin-right: 5px;
    vertical-align: middle;
}
.ant-btn-circle .anticon {
    margin: 0 !important;
}
